import { Reducer } from 'redux';
import { PayloadAction } from '../../../../../store/payload-action';

export class CacheFormState {
  datas:  {[key: string]: any};
  loading: boolean;
  error: any;
}

const CACHE_FORM_INITIAL_STATE: CacheFormState = {
  datas: null,
  loading: false,
  error: null,
};

export class CacheFormConstant {

  static readonly ADD_CACHE_FORM_START = '[CacheForm].ADD_CACHE_FORM_START';

  static readonly ADD_CACHE_FORM_SUCCEEDED = '[CacheForm].ADD_CACHE_FORM_SUCCEEDED';

  static readonly ADD_CACHE_FORM_FAILED = '[CacheForm].ADD_CACHE_FORM_FAILED';

  static readonly UPDATE_CACHE_FORM_SUCCEEDED = '[CacheForm].UPDATE_CACHE_FORM_SUCCEEDED';

  static readonly REMOVE_CACHE_FORM_SUCCEEDED = '[CacheForm].REMOVE_CACHE_FORM_SUCCEEDED';

}

export const cacheFormReducer: Reducer<CacheFormState> = (state: CacheFormState = CACHE_FORM_INITIAL_STATE, action: PayloadAction<{[key: string]: any}>): CacheFormState => {
  switch (action.type) {
    case CacheFormConstant.ADD_CACHE_FORM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CacheFormConstant.ADD_CACHE_FORM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };

    case CacheFormConstant.REMOVE_CACHE_FORM_SUCCEEDED:
      const formToDeleteKey: string = Object.keys(action.payload)[0];
      if (state.datas.hasOwnProperty(formToDeleteKey)) {
        delete state.datas[formToDeleteKey];
      }
      return {
        ...state,
        datas: {
        ...state.datas
        },
        loading: false,
        error: null,
      };

    case CacheFormConstant.UPDATE_CACHE_FORM_SUCCEEDED:
      const formToUpdateKey: string = Object.keys(action.payload)[0];
      const curFormCache: any = state.datas[formToUpdateKey];
      const dataToUpdateKeys: string[] = Object.keys(action.payload[formToUpdateKey]);
      dataToUpdateKeys.forEach((dataToUpdateKey: string) => {
        if (action.payload[formToUpdateKey][dataToUpdateKey] instanceof Array) {
          curFormCache[dataToUpdateKey] = !!curFormCache[dataToUpdateKey] ? [...action.payload[formToUpdateKey][dataToUpdateKey], ...curFormCache[dataToUpdateKey]] : action.payload[formToUpdateKey][dataToUpdateKey];
        } else {
          curFormCache[dataToUpdateKey] = action.payload[formToUpdateKey][dataToUpdateKey];
        }
      });
      // TO IMPLEMENT
      return {
        ...state,
        datas: {
          ...state.datas
        },
        loading: false,
        error: null,
      };

    case CacheFormConstant.ADD_CACHE_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
