import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { AlertModalService } from '../alert-message/service/alert-modal.service';
import {ErrorDetails, ErrorMessageFormater } from '../../core/ui/toaster/service/errorMessageFormater';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public static readonly BYPASS_HEADER = 'X-Skip-Error-Interceptor';

  constructor(
    private translateService: TranslateService,
    private toastaService: ToastaService,
    private alertService: AlertModalService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(HttpErrorInterceptor.BYPASS_HEADER)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError(
        (error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {

          if (error.status === 401) {
            this.alertService.raiseDisconnectError();
          }
          let errorMsg: ToastOptions = {
            title: this.translateService.instant('HTTP_ERRORS.' + error.status, {status: error.status}),
            timeout: 0,
            showClose: true
          };

          if (!!error.error) {
            try {
              let errorObject: any;
              if (typeof error.error === 'string') {
                // réponse type 400 + DocumentError : {"fields":{},"links":{},"documents":["title is mandatory in entity"]}
                errorObject = JSON.parse(error.error);
              } else {
                // réponse type DocumentErrorException :
                // {
                //   "title": "validation errors",
                //   "status": 400,
                //   "detail": "a validation failed",
                //   "errors": {
                //   "fields": {},
                //   "links": {},
                //   "documents": [
                //     "Le document est en cours d’édition"
                //   ],
                //     "id": "SggBUIoBij3Fq8E-qTyN",
                //     "cdx_id": "SggBUIoBij3Fq8E-qTyN"
                // }
                // }
                errorObject = error.error;
              }
              let details: ErrorDetails;
              // FIXME (côté back) le retour du back est différent selon que l'on envoie une DocumentError ou une DocumentErrorException
              if (errorObject.errors) {
                details = ErrorMessageFormater.extractErrors(errorObject.errors);
              } else if (error.status === 400 && !!errorObject.fields && !!errorObject.links && !!errorObject.documents) {
                details = ErrorMessageFormater.extractErrors(errorObject);
              }
              if (details) {
                errorMsg = {
                  title: `<h5>${errorMsg.title}</h5><h6>${ErrorMessageFormater.getErrorTitleFromUrl(error.url)}</h6>`,
                  msg: details.rubriques.map(rubrique => {
                    const erreursFormatees = rubrique.listeErreurs.map(erreur => `<dd>${erreur}</dd>`);
                    return `<dt>${rubrique.titre}</dt>${erreursFormatees}`;
                  }).join(''),
                  timeout: 0,
                  showClose: true
                };
              } else {
                errorMsg.msg = errorObject.detail;
              }

              // if (!!errorObject.detail) {
              //   errorMsg.msg = errorObject.detail;
              // } else if (error.status === 400 && !!errorObject.fields && !!errorObject.links && !!errorObject.documents) {
              //   const details = ErrorMessageFormater.extractErrors(errorObject);
              //   errorMsg = {
              //     title: `<h5>${errorMsg.title}</h5><h6>${ErrorMessageFormater.getErrorTitleFromUrl(error.url)}</h6>`,
              //     msg: details.rubriques.map(rubrique => {
              //       const erreursFormatees = rubrique.listeErreurs.map(erreur => `<dd>${erreur}</dd>`);
              //       return `<dt>${rubrique.titre}</dt>${erreursFormatees}`;
              //     }).join(''),
              //     timeout: 0,
              //     showClose: true
              //   };
              // }
            } catch (e) {
              if (!!error.error.detail) {
                errorMsg.msg = error.error.detail;
              }
            }
          }

          this.toastaService.error(errorMsg);
          return throwError(error as HttpErrorResponse);
      })
    );
  }

}
